import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { injectIntl } from "react-intl";
import Ansi from "ansi-to-react";

import logger from "Libs/logger";

import Loading from "Components/Loading";
import ModalWrapper from "Components/ModalWrapper";
import Button from "Components/Button";
import EmptyBackups from "Components/illustrations/EmptyBackups";
import Heading2 from "Components/styleguide/Heading2";

const BackupsListLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 0;
`;

const BackupsListScroll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  margin: 0 -32px;
  border-top: 1px solid #d8e2f0;
`;

const ShowMoreWrapper = styled.div`
  text-align: center;
  padding-top: 14px;
  border-top: 1px solid #d8e2f0;
  a.button {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    height: 32px;
    padding: 0 24px;
    border-radius: 4px;
  }
`;

const EmptyText = styled.div`
  margin-top: 70px;
  margin-bottom: 300px;
  text-align: center;
  .image-wrapper {
    margin: 0 auto 32px;
  }
  h2 {
    margin-bottom: 16px;
  }
  p {
    color: #5f5e70;
    font-size: 15px;
  }
`;

// The import function use require because it is not yet implementde in browser
// http://caniuse.com/#feat=es6-module
const getActivityComponent = type => {
  let module;
  try {
    module = require("Components/activities/" +
      type
        .replace(/\./g, "/")
        .replace(
          /\/([A-z_\-0-9]+)$/,
          x => "/" + x.charAt(1).toUpperCase() + x.slice(2)
        )).default;
  } catch (err) {
    logger(err, type);
    module = require("Components/activities/Unknown").default;
  }

  return module;
};

const sortActivities = (activity1, activity2) =>
  new Date(activity2.created_at).getTime() -
  new Date(activity1.created_at).getTime();

class BackupsList extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.updateActivity = this.updateActivity.bind(this);
    this.state = {};
  }

  componentDidMount() {
    this.updateActivity();
    this.refreshTimer = setInterval(() => {
      this.updateActivity();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refresh);
  }

  updateActivity() {
    this.setState({
      refresh: new Date()
    });
  }

  openModal(modalContent) {
    this.setState({
      isModalOpen: true,
      modalContent
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  loadMore() {
    this.props.loadMore(this.state.filterValue || {});
  }

  render() {
    const activities =
      this.props.activities && this.props.activities.valueSeq();
    const { intl, activityContext } = this.props;
    const { modalContent = {}, isLoading } = this.state;

    return (
      <BackupsListLayout>
        {activities.size === 0 ? (
          <EmptyText className="empty-text">
            <div className="image-wrapper">
              <EmptyBackups />
            </div>
            <Heading2>No backups</Heading2>
            <p>Make sure you backup your work. When you do it'll show here.</p>
          </EmptyText>
        ) : (
          <BackupsListScroll>
            <InfiniteScroll
              loadMore={this.loadMore}
              hasMore={this.props.hasMore}
              initialLoad={false}
              loader={
                this.props.activityIsLoading ? (
                  <Loading iconOnly={true} />
                ) : null
              }
              useWindow={false}
            >
              {activities.sort(sortActivities).map(activity => {
                const Activity = getActivityComponent(activity.type);
                if (
                  activity.type === "environment.backup" ||
                  activity.type === "environment.restore"
                ) {
                  return (
                    <Activity
                      key={activity.id}
                      openModal={this.openModal.bind(this, activity.log)}
                      activity={activity}
                      environmentPublicUrl={this.props.environmentPublicUrl}
                      canEditProject={this.props.canEditProject}
                      activityContext={activityContext}
                    />
                  );
                }
                return;
              })}
            </InfiniteScroll>
          </BackupsListScroll>
        )}
        <ShowMoreWrapper>
          {this.props.hasMore && (
            <Button
              onClick={() => {
                this.loadMore();
                document.activeElement.blur();
              }}
              className="link-style"
            >
              Show more
            </Button>
          )}
        </ShowMoreWrapper>
        <ModalWrapper
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
          modalClass="modal-build-log modal-large"
          title={intl.formatMessage({ id: "build_log" })}
          copyText={modalContent ? modalContent : "No logs"}
        >
          <pre id="log-scroll">
            {isLoading ? (
              <Loading />
            ) : modalContent === "" ? (
              "No logs"
            ) : (
              <Ansi>{modalContent || ""}</Ansi>
            )}
          </pre>
        </ModalWrapper>
      </BackupsListLayout>
    );
  }
}

BackupsList.propTypes = {
  activities: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  environmentPublicUrl: PropTypes.string,
  canEditProject: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  loadActivitiesOfType: PropTypes.func,
  intl: PropTypes.object,
  filter: PropTypes.bool,
  activityContext: PropTypes.string,
  hasMore: PropTypes.bool,
  activityIsLoading: PropTypes.bool
};

export default injectIntl(BackupsList);
