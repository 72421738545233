import React from "react";

const EmptyBackups = () => (
  <svg
    width="135"
    height="118"
    viewBox="0 0 135 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#empty-backup-clip0)">
      <path
        d="M96.66 55.47H30.66C29.01 55.47 27.66 56.82 27.66 58.47V78.47C27.66 80.12 29.01 81.47 30.66 81.47H60.86L60.76 81.37L62.66 79.47H30.66C30.11 79.47 29.66 79.02 29.66 78.47V58.47C29.66 57.92 30.11 57.47 30.66 57.47H96.66C97.21 57.47 97.66 57.92 97.66 58.47V78.47C97.66 79.02 97.21 79.47 96.66 79.47H73.49L75.49 81.47H96.66C98.31 81.47 99.66 80.12 99.66 78.47V58.47C99.66 56.82 98.31 55.47 96.66 55.47Z"
        fill="#4B6180"
      />
      <path
        opacity="0.3"
        d="M104.66 46.47H38.91C37.81 46.47 36.91 45.57 36.91 44.47V24.27C36.91 23.17 37.81 22.27 38.91 22.27H104.66C105.76 22.27 106.66 23.17 106.66 24.27V44.47C106.66 45.57 105.77 46.47 104.66 46.47Z"
        fill="#5792FF"
      />
      <path
        d="M105.36 100.47H21.96C9.49 102.3 1.66 104.94 1.66 107.87C1.66 113.39 29.42 117.87 63.66 117.87C97.9 117.87 125.66 113.39 125.66 107.87C125.66 104.94 117.83 102.3 105.36 100.47Z"
        fill="#C9D0E4"
      />
      <path
        opacity="0.3"
        d="M60.85 85.29C59.59 85.29 58.81 83.75 59.69 82.84C59.7 82.83 59.7 82.83 59.71 82.82L67.3 75.23C67.89 74.64 68.84 74.64 69.42 75.23L77.01 82.82C77.02 82.83 77.02 82.83 77.03 82.84C77.91 83.75 77.13 85.29 75.87 85.29H60.85Z"
        fill="#5792FF"
      />
      <path
        d="M25.49 7.46997L22.66 10.3"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.59 1.46997L14.66 12.4"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.66 4.64001C22.79 4.77001 25.49 7.47001 25.49 7.47001"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.66 7.47001L9.49 4.64001"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.49 10.3C9.36 10.17 6.66 7.46997 6.66 7.46997"
        stroke="#C9D0E4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        opacity="0.3"
        d="M106.68 6.47001C107.774 6.47001 108.66 5.58353 108.66 4.49001C108.66 3.39649 107.774 2.51001 106.68 2.51001C105.586 2.51001 104.7 3.39649 104.7 4.49001C104.7 5.58353 105.586 6.47001 106.68 6.47001Z"
        fill="#5792FF"
      />
      <path
        d="M97.68 12.47C98.7735 12.47 99.66 11.5835 99.66 10.49C99.66 9.39649 98.7735 8.51001 97.68 8.51001C96.5865 8.51001 95.7 9.39649 95.7 10.49C95.7 11.5835 96.5865 12.47 97.68 12.47Z"
        fill="#4B6180"
      />
      <path
        d="M106.33 7.66C108.169 7.66 109.66 6.16911 109.66 4.33C109.66 2.49089 108.169 1 106.33 1C104.491 1 103 2.49089 103 4.33C103 6.16911 104.491 7.66 106.33 7.66Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4.33 72.66C6.16911 72.66 7.66 71.1691 7.66 69.33C7.66 67.4909 6.16911 66 4.33 66C2.49089 66 1 67.4909 1 69.33C1 71.1691 2.49089 72.66 4.33 72.66Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.33 36.33C40.9869 36.33 42.33 34.9869 42.33 33.33C42.33 31.6732 40.9869 30.33 39.33 30.33C37.6731 30.33 36.33 31.6732 36.33 33.33C36.33 34.9869 37.6731 36.33 39.33 36.33Z"
        fill="white"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.33 46.33C40.9869 46.33 42.33 44.9869 42.33 43.33C42.33 41.6732 40.9869 40.33 39.33 40.33C37.6731 40.33 36.33 41.6732 36.33 43.33C36.33 44.9869 37.6731 46.33 39.33 46.33Z"
        fill="white"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.33 66.33C40.9869 66.33 42.33 64.9869 42.33 63.33C42.33 61.6732 40.9869 60.33 39.33 60.33C37.6731 60.33 36.33 61.6732 36.33 63.33C36.33 64.9869 37.6731 66.33 39.33 66.33Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.33 76.33C40.9869 76.33 42.33 74.9869 42.33 73.33C42.33 71.6732 40.9869 70.33 39.33 70.33C37.6731 70.33 36.33 71.6732 36.33 73.33C36.33 74.9869 37.6731 76.33 39.33 76.33Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M96.66 50.47H30.66C29.56 50.47 28.66 49.57 28.66 48.47V28.47C28.66 27.37 29.56 26.47 30.66 26.47H96.66C97.76 26.47 98.66 27.37 98.66 28.47V48.47C98.66 49.57 97.77 50.47 96.66 50.47Z"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M126.47 65.5C125.96 65.53 125.43 65.46 124.87 65.29C124.31 65.12 123.94 64.5 124.2 63.91C124.39 63.45 124.95 63.2 125.38 63.29C125.72 63.41 126.16 63.5 126.57 63.5C127.08 63.47 127.49 63.88 127.52 64.39C127.55 64.91 127.07 65.45 126.47 65.5Z"
        fill="#C9D0E4"
      />
      <path
        d="M121.06 62.97C120.64 62.56 120.3 62.03 119.98 61.6C119.64 61.07 119.87 60.39 120.43 60.14C120.89 59.92 121.45 60.08 121.67 60.54C121.87 60.9 122.16 61.24 122.45 61.58C122.77 62.01 122.8 62.52 122.48 62.91C122.08 63.32 121.38 63.4 121.06 62.97Z"
        fill="#C9D0E4"
      />
      <path
        d="M121.36 51.32C121.05 51.71 121.2 52.29 121.66 52.48L124 53.53L125.87 54.39C126.45 54.65 126.94 54.12 126.79 53.53L126.27 51.5C129.11 51.28 131.84 53.06 132.59 55.96C133.26 58.57 132.1 61.25 129.88 62.65C129.42 62.87 129.26 63.43 129.48 63.89C129.73 64.45 130.41 64.68 130.94 64.34C133.86 62.45 135.41 58.85 134.53 55.46C133.51 51.49 129.68 49.07 125.78 49.56L125.31 47.72C125.14 47.04 124.48 46.9 124.09 47.41L122.87 49.07L121.36 51.32Z"
        fill="#C9D0E4"
      />
      <path
        d="M61.66 86.47V99.47H14.66"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="12 12"
      />
      <path
        d="M66.66 86.47V99.47H113.66"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="12 12"
      />
      <path
        d="M57.66 85.97H55.09L62.87 78.19C63.65 77.41 64.92 77.41 65.7 78.19L73.48 85.97H70.66"
        stroke="#4B6180"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="empty-backup-clip0">
        <rect width="134.76" height="117.87" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyBackups;
