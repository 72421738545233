import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import ImmutablePropTypes from "react-immutable-proptypes";
import { injectIntl } from "react-intl";

import { loadEnvironment } from "Reducers/environment";
import {
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities
} from "Reducers/activity";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import ListGroup from "Components/ListGroup";
import EnvironmentBackupsList from "Containers/EnvironmentBackupsList";
import Loading from "Components/Loading";
import { List } from "immutable";

const BackupsWrapper = styled.div`
  padding: 32px 32px 16px;
  button {
    margin-bottom: 32px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

class Backups extends React.Component {
  constructor(props) {
    super(props);
    this.loadMoreActivities = this.loadMoreActivities.bind(this);
    this.loadActivitiesOfType = this.loadActivitiesOfType.bind(this);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    if (this.props.environment && this.props.environment.id) {
      this.props.loadEnvironmentActivities(
        this.props.params.projectId,
        this.props.params.environmentId,
        "snapshot"
      );
    }
    setTimeout(
      () =>
        this.setState({
          loading: false
        }),
      1500
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.environment.id &&
      (this.props.params.environmentId !== nextProps.params.environmentId ||
        this.props.environment.id !== nextProps.environment.id)
    ) {
      this.props.loadEnvironment(
        nextProps.currentProjectOrganization,
        nextProps.params.environmentId
      );
      this.props.loadEnvironmentActivities(
        nextProps.params.projectId,
        nextProps.params.environmentId,
        "snapshot"
      );
    }
  }

  loadActivitiesOfType() {
    this.props.loadEnvironmentActivities(
      this.props.params.projectId,
      this.props.params.environmentId,
      "snapshot"
    );
  }

  loadMoreActivities() {
    if (!this.props.isLoading) {
      this.props.loadMoreEnvironmentActivities(
        this.props.params.projectId,
        this.props.params.environmentId,
        "snapshot"
      );
    }
  }
  render() {
    if (!this.props.environment) {
      return false;
    }
    const environmentPublicUrl =
      this.props.environment._links &&
      this.props.environment._links["public-url"] &&
      this.props.environment._links["public-url"].href;

    return (
      <ContentLayout>
        <ListGroup>
          <BackupsWrapper className="environment-backups">
            <HeadingWrapper>
              <Heading2>Backups</Heading2>
            </HeadingWrapper>
            {this.state.loading ? (
              <Loading />
            ) : (
              <React.Fragment>
                {this.props.environment.id && (
                  <EnvironmentBackupsList
                    organizationId={this.props.organizationId}
                    projectId={this.props.projectId}
                    loadMore={this.loadMoreActivities}
                    loadActivitiesOfType={this.loadActivitiesOfType}
                    activities={this.props.activity}
                    environmentPublicUrl={environmentPublicUrl}
                    canEditProject={
                      this.props.project &&
                      this.props.project.hasPermission("#edit")
                    }
                    isLoading={this.props.activityIsLoading}
                    hasMore={this.props.hasMore}
                    activityContext="backups"
                  />
                )}
              </React.Fragment>
            )}
          </BackupsWrapper>
        </ListGroup>
      </ContentLayout>
    );
  }
}

Backups.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    organizationId: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  intl: PropTypes.object,
  loadEnvironment: PropTypes.func,
  loadActivities: PropTypes.func,
  environment: PropTypes.object,
  project: PropTypes.object,
  loadMoreEnvironmentActivities: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  loadEnvironmentActivities: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  activity: PropTypes.oneOfType([
    ImmutablePropTypes.map,
    ImmutablePropTypes.list
  ]),
  activityIsLoading: PropTypes.bool,
  hasMore: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  environment:
    state.environment &&
    state.environment.getIn(
      [
        "data",
        props.params.organizationId,
        props.params.projectId,
        props.params.environmentId
      ],
      {}
    ),
  activity: state.activity.getIn(
    [
      "byEnvironment",
      "data",
      props.params.organizationId,
      props.params.projectId,
      props.params.environmentId
    ],
    new List()
  ),
  hasMore: state.activity.getIn(["byProject", "hasMore"], false),
  project:
    state.project &&
    state.project.getIn([
      "data",
      props.params.organizationId,
      props.params.projectId
    ]),
  isLoading: state.activity && state.activity.get("loading")
});

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironment: (
    currentProjectOrganization = props.currentProjectOrganization,
    currentEnvironmentId = props.params.environmentId
  ) =>
    dispatch(
      loadEnvironment(
        currentEnvironmentId,
        props.params.projectId,
        currentProjectOrganization
      )
    ),
  loadEnvironmentActivities: (projectDescriptionId, environmentDescriptionId) =>
    dispatch(
      loadEnvironmentActivities(
        projectDescriptionId,
        environmentDescriptionId,
        props.params.organizationId,
        "snapshot"
      )
    ),
  loadMoreEnvironmentActivities: (
    projectDescriptionId,
    environmentDescriptionId
  ) =>
    dispatch(
      loadMoreEnvironmentActivities(
        projectDescriptionId,
        environmentDescriptionId,
        "snapshot"
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Backups));
